import * as React from "react";
import { Helmet } from "react-helmet";
import SucessPage from "../components/successPage/successPage";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import SEO from "../components/seo/SEO";
import Layout from "../components/layout/Layout";

export default function Success({ location }) {
  return (
    <Layout>
      <SEO />
      <Helmet>
        <title>Installation Successful - /meet 4 Slack</title>
      </Helmet>
      <SucessPage location={location} />
    </Layout>
  );
}
